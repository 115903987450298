import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PATHS } from 'Routes/constants';
import { MenuVisibilityContext } from 'contexts/menuVisibility';
import SignOutIcon from 'assets/images/SignOutIcon';
import useNewMenuContext, { MENU_TYPE } from 'contexts/new-menu';
import useAccountRegistry, { ACCOUNT_TYPE } from 'contexts/account-registry';
import UserAuthorizationContext from 'contexts/authorization';
import MenuList from './MenuList';
import MenuListItem from './MenuListItem';
import NewArrowLeftIcon from 'assets/images/NewArrowLeftIcon';
import MenuCardInfoAccount from './MenuCardInfoAccount';
import MenuButtonSwitchProfile from './MenuButtonSwitchProfile';
import MenuNavPrimaryAccountInfo from './MenuNavPrimaryAccountInfo';
import { NAV_MENU_OPEN_CLASS, NAV_MENU_CLOSE_CLASS } from '../constants';
import { APP_CONTBANNK_VERSION } from 'config/constants';
import { camelize, clearURL, maskCpfOrCnpj, toAccountNumber } from 'tools';
import Configs from 'styles/variables';
import useAgentBusinessProfiles from 'hooks/useAgentBusinessProfiles';
import AlertProgress from 'components/AlertProgress';
import useAccounts from 'hooks/useAccounts';
import { forceStringCapitalize, truncate } from 'tools/string';
import useLogout from 'hooks/useLogout';
import * as externalLink from 'tools/externalLink';
import * as native from 'tools/app-native';
import * as S from '../style';
import useToken from 'hooks/useToken';
import useAccountsService from 'services/accounts';

export default function MenuNavPrimary() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('menu');
  const { doLogout } = useLogout();
  const token = useToken();
  const { getAccountRegistry } = useAccountsService(token);
  const { registry: account } = useAccountRegistry();
  const { menuIsOpen, menuIsClose, handleCloseMenu } = useContext(
    MenuVisibilityContext
  );
  const { changeMenu, menuItems, menuView } = useNewMenuContext();
  const { currentAgentProfile } = useAgentBusinessProfiles();
  const { hasBusinessAgent } = useContext(UserAuthorizationContext);
  const [alertProgressOpen, setAlertProgressOpen] = useState(false);
  const { currentAccount } = useAccounts();
  const [alertDescription, setAlertDescription] = useState('');
  const [alertName, setAlertName] = useState('');
  const [bankInformation, setBankInformation] = useState();

  const navRef = useRef(null);

  const { pathname } = location;

  if (menuIsOpen && navRef?.current) {
    navRef.current.scrollTop = 0;
  }

  const handleOnChangeToAgentProfile = () => {
    if (location.pathname !== PATHS.AGENT.DASHBOARD) {
      history.push(PATHS.AGENT.DASHBOARD);
    }
  };

  const handleOnChangeToBankProfile = () => {
    if (location.pathname !== PATHS.BANK.DASHBOARD) {
      history.push(PATHS.BANK.DASHBOARD);
    }
  };

  const alertProgressCallback = () => {
    if (hasBusinessAgent) {
      setAlertProgressOpen(false);
      changeMenu();
      if (menuView === MENU_TYPE.BANK) {
        handleOnChangeToAgentProfile();
      } else {
        handleOnChangeToBankProfile();
      }
    }
  };

  const handleSwitchMenuProfile = () => {
    if (hasBusinessAgent) {
      if (menuView === MENU_TYPE.BANK) {
        setAlertDescription(
          t('menu:new.progress_bar_agent', 'Entrando no Perfil de Agente:')
        );
        setAlertName(
          forceStringCapitalize(truncate(currentAgentProfile?.name, 32))
        );
      }

      if (menuView !== MENU_TYPE.BANK) {
        setAlertDescription(
          t('menu:new.progress_bar_account', 'Entrando na Conta de:')
        );
        setAlertName(forceStringCapitalize(truncate(currentAccount?.name, 32)));
      }

      setAlertProgressOpen(true);
      handleCloseMenu();
    }
  };

  const handleClick = () => {
    handleCloseMenu();
  };

  const getBankInformation = useCallback(async () => {
    getAccountRegistry(token, true)
      .then((response) => {
        const information = {
          bankName: response.data.bank_information.name,
          bankCode: response.data.bank_information.code,
          branch: response.data.information.branch,
          number: toAccountNumber(response.data.information.number),
        };

        setBankInformation(information);
      })
      .catch((error) => console.log(error.response));
  }, [token, getAccountRegistry]);

  const isCurrentPath = (path) => {
    const clearedPathName = clearURL(pathname);

    return clearedPathName === clearURL(path);
  };

  const isBusinessAccount = useMemo(
    () => account?.account_type === ACCOUNT_TYPE.BUSINESS,
    [account]
  );

  useEffect(() => {
    let mounted = true;

    if (mounted && token) {
      getBankInformation();
    }

    return () => {
      mounted = false;
    };
  }, [token, getBankInformation]);

  return (
    <>
      <S.MenuNavPrimary
        ref={navRef}
        className={`${menuIsOpen ? NAV_MENU_OPEN_CLASS : ''} ${
          menuIsClose ? NAV_MENU_CLOSE_CLASS : ''
        }`}
      >
        <S.HeaderBackButtonMobile>
          <button onClick={handleClick}>
            <NewArrowLeftIcon fill={Configs.newColors.n0} />
          </button>
        </S.HeaderBackButtonMobile>
        <div>
          <MenuNavPrimaryAccountInfo
            account={account}
            agent={currentAgentProfile}
            isBusinessAccount={isBusinessAccount}
          />
          <MenuCardInfoAccount
            document={maskCpfOrCnpj(account?.identifier)}
            bankInformation={bankInformation}
            name={camelize(account?.business_name || account?.register_name)}
            isCompany={isBusinessAccount}
          />
          <MenuButtonSwitchProfile
            leftButtonText={t('menu:new.my_contbank', 'Meu Contbank')}
            leftButtonOnClick={handleSwitchMenuProfile}
            leftButtonActive={menuView === MENU_TYPE.BANK}
            rightButtonText={t('menu:new.agent_profile', 'Perfil de Agente')}
            rightButtonOnClick={handleSwitchMenuProfile}
            rightButtonActive={menuView === MENU_TYPE.BUSINESS_AGENT}
            allowLeftButton={hasBusinessAgent}
          />
          <MenuList>
            {menuItems &&
              menuItems.map((item, index) => {
                if (
                  item.external &&
                  native.isAppNative() &&
                  !native.isEnableExternalBrowser()
                ) {
                  return null;
                }

                return (
                  <MenuListItem
                    key={item.id + index}
                    icon={item.icon}
                    to={
                      item.external
                        ? {
                            pathname: externalLink.parseURL(item.location),
                          }
                        : item.location
                    }
                    label={item.label}
                    target={item.external && '_blank'}
                    className={
                      isCurrentPath(item.location) ? 'active' : undefined
                    }
                  />
                );
              })}
          </MenuList>
          <MenuList>
            <MenuListItem
              icon={<SignOutIcon fill={Configs.newColors.n200} />}
              label={t('menu:new.exit', 'Sair da Conta')}
              onClick={() => doLogout()}
            />
          </MenuList>
        </div>
        <S.MenuAppVersion>{APP_CONTBANNK_VERSION}</S.MenuAppVersion>
      </S.MenuNavPrimary>
      <AlertProgress
        isOpen={alertProgressOpen}
        callback={alertProgressCallback}
      >
        <p>{alertDescription}</p>
        <strong>{alertName}</strong>
      </AlertProgress>
    </>
  );
}
