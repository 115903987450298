import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NewCopyIcon from 'assets/images/NewCopyIcon';
import Configs from 'styles/variables';
import * as S from '../style';
import { copyTextToClipboard } from 'tools';

export default function MenuCardInfoAccount({
  bankInformation,
  document = '',
  isCompany = false,
  name = '',
}) {
  const { t } = useTranslation('menu');
  const [copied, setCopied] = useState(false);

  const handleClipboard = () => {
    const text = parseInfoAccountText(
      t('menu:bank', 'Banco'),
      bankInformation?.bankCode,
      bankInformation?.bankName,
      t('menu:infoAccount.branch', 'Agência'),
      bankInformation?.branch,
      t('menu:infoAccount.account', 'Conta'),
      bankInformation?.number,
      name,
      isCompany
        ? t('menu:infoAccount.cnpj', 'CNPJ')
        : t('menu:infoAccount.cpf', 'CPF'),
      document
    );

    copyTextToClipboard(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  return (
    <S.CardInfoAccount>
      <S.CardInfoAccountLine>
        <div>
          <span>{t('menu:bank', 'Banco')}</span>
          <h4>
            {bankInformation?.bankCode} - {bankInformation?.bankName}
          </h4>
        </div>
        <div>
          <span>
            <button onClick={handleClipboard}>
              {!copied && <NewCopyIcon fill={Configs.newColors.n0} />}
              {copied && <span>Copiado</span>}
            </button>
          </span>
        </div>
      </S.CardInfoAccountLine>

      <S.CardInfoAccountLine>
        <div>
          <span>{t('menu:infoAccount.branch', 'Agência')}</span>
          <h4>{bankInformation?.branch}</h4>
        </div>
        <div>
          <span>{t('menu:infoAccount.account', 'Conta')}</span>
          <h4>{bankInformation?.number}</h4>
        </div>
        <div>
          <span>
            {isCompany
              ? t('menu:infoAccount.cnpj', 'CNPJ')
              : t('menu:infoAccount.cpf', 'CPF')}
          </span>
          <h4>{document}</h4>
        </div>
      </S.CardInfoAccountLine>
    </S.CardInfoAccount>
  );
}

function parseInfoAccountText(
  bankLabel,
  bankNumberInfo,
  bankNameInfo,
  branchLabel,
  branchInfo,
  accountLabel,
  accountInfo,
  nameInfo,
  documentLabel,
  documentNumberInfo
) {
  return `${bankLabel}: ${bankNumberInfo} - ${bankNameInfo}\n${branchLabel}: ${branchInfo}\n${accountLabel}: ${accountInfo}\n${nameInfo}\n${documentLabel}: ${documentNumberInfo}`;
}
